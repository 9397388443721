body {
  background-color: white;
  color: black;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  min-height: 8vh;
  pointer-events: none;
  text-align: right;
}

.App-header {
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: black;
} 

.downloads20 {
  align-items: left;
  text-align: left;
  font-size: 20px;
  color: black;
}

.downloads17 {
  align-items: left;
  text-align: left;
  font-size: 17px;
}
.downloads17b {
  align-items: left;
  text-align: left;
  font-size: 17px;
  color: darkblue;
}

.centerBlock {
  margin: auto;
  width: 70%;
  border: 1px solid rgb(180,180,180);
  padding: 10px;
  border-radius: 12px;
}
.centerBlockB {
  margin: auto;
  width: 70%;
  border: 1px solid #7c97ac;
  padding: 10px;
  border-radius: 12px
}

.pad {
  padding: 50px;
}

.t1 {
  align-items: center;
  font-size: 20px;
  color: #415b6e;
}
.t1l {
  align-items: center;
  text-align: left;
  font-size: 20px;
  color: black;
}
.t2 {
  align-items: center;
  font-size: 30px;
  color: black;
}
.t3l {
  align-items: center;
  text-align: left;
  font-size: 18px;
  color: black;
}

.button {
  background-color: #2a6592;
  border: none;
  color: white;
  padding: 14px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin:20px 20px;
  font-size: 20px;
  border-radius: 12px;
}

input[type="button"] {
  background-color: #2a6592;
  border: none;
  color: white;
  padding: 14px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin:20px 20px;
  font-size: 20px;
  border-radius: 12px;
}

.img {
  float: right;
}

.text {
  font-size: 30px;
  padding-left: 20px;
  padding-top: 10px;
  float: left;
  text-align: left;
}
.text20 {
  font-size: 20px;
  padding-left: 20px;
  float: left;
  text-align: left;
}


.box100 {
  float: left;
  width: 100%;
  padding: 5px;
}

.box1 {
  float: left;
  width: 33%;
  padding: 5px;
}
.box2 {
  float: left;
  width: 66%;
  padding: 5px;
}

.App-link {
  color: #4e6091;
}
